<template>
  <div class="list-document-types-login" @mouseleave="openListDocuments = false">
    <div class="list-document-types-login__option-selected" @click="() => openListDocuments = !openListDocuments">
      <div>{{valueSelected}}</div>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 9 5" fill="none" :style="`${openListDocuments ? 'transform: rotate(180deg);': ''}`">
          <path d="M8 0.5L4.5 4L1 0.499999" stroke="#444B57" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="divisor"></div>
    </div>
    <div class="list-document-types-login__options" v-if="openListDocuments">
      <div class="list-document-types-login__options__bg">
        <div v-for="(document, index) in documentTypesList" :key="index" @click="selectTypeDocument(document.value)">
          {{document.value}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['valueSelected'],
  data () {
    return {
      openListDocuments: false,
      documentTypesList: []
    };
  },
  mounted () {
    this.getIdentification();
  },
  methods: {
    selectTypeDocument (value) {
      this.openListDocuments = false;
      this.$emit('selectTypeDocument', value);
    },
    getIdentification () {
      this.$http.get('payment/identifications/' + this.vendor.id).then(({ data }) => {
        if (data.code === 200) {
          this.documentTypesList = data.data.map((i) => {
            return {
              name: i.name,
              value: i.id
            };
          });
        }
      });
    },
    openList () {
      this.openListDocuments = !this.openListDocuments;
    }
  }
};
</script>

<style lang="scss">
  .list-document-types-login{
    position: relative;
    min-width: 45px;
    height: 30px;
    padding: 0 10px;
    align-content: center;
    z-index: 90;
    font-size: 12px;
    display: flex;
    &__option-selected{
      cursor: pointer;
      gap: 6px;
      display: flex;
      align-items: center;
    }
    &__options{
      position: absolute;
      /* top: 32px; */
      width: 100%;
      left: 0;
      &__bg{
        display: grid;
        border-radius: 4px;
        margin-top: 7px;
        background-color: white;
        overflow: hidden;
        padding-top: 6px;
        padding-bottom: 6px;
        > div{
          padding: 0px 8px;
          &:hover{
            cursor: pointer;
            background: #A8ADB5;
          }
        }
      }
    }
    .divisor{
      height: 20px;
      min-width: 1px;
      border-radius: 4px;
      background: #A8ADB5;
      z-index: 2;
    }
  }
</style>
